import React, { useContext } from "react"
import "./style.scss"

import DownArrow from "../DownArrow"
import coffee from "../../images/original/roland-denes-unsplash.jpg"
import { ModalContext } from "../../ModalContext"

const LANGUAGES = ["Javascript", "Python"]
const FRONTEND = ["react", "redux", "typescript"]
const BACKEND = ["django", "nodejs", "postgresql"]
const GIS = [
  "Leaflet",
  "postGIS",
  "Geodjango",
  "Arcgis Pro",
  "Arcgis Online",
  "FME",
]
const CERTIFICATES = [
  {
    name: "PCAP™ – Certified Associate Python Programmer",
    link: "https://www.credly.com/badges/64f31db8-388b-4f39-bdbe-56a74f45f7e8",
  },
  {
    name: "AWS Cloud Practitioner",
    link: "https://www.credly.com/badges/225726a6-d2b9-4a25-b00b-74e58cb7a3dd?source=linked_in_profile",
  },
]
export default function Skill({ refProp }) {
  let { handleShowModal, FontAwesomeIcon, faDesktop } = useContext(ModalContext)

  return (
    <section ref={refProp} id="skill">
      <div className="container fixed">
        <div className="skill__content">
          <h2
            className="skill__head"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="800"
            data-sal-easing="easeOutCubic"
          >
            Skills
          </h2>
          <div className="skill__body">
            <div className="skill__item">
              <img className="skill__img" src={coffee} alt="Hi! I'm Beck" />
            </div>
            <div className="skill__item">
              <h3>LANGUAGES</h3>
              <div className="skill__skills">
                {LANGUAGES.map(item => (
                  <span className="tag">{item.toUpperCase()}</span>
                ))}
              </div>
              <h3>FRONTEND</h3>
              <div className="skill__skills">
                {FRONTEND.map(item => (
                  <span className="tag">{item.toUpperCase()}</span>
                ))}
              </div>
              <h3>BACKEND</h3>
              <div className="skill__skills">
                {BACKEND.map(item => (
                  <span className="tag">{item.toUpperCase()}</span>
                ))}
              </div>
              <h3>GIS</h3>
              <div className="skill__skills">
                {GIS.map(item => (
                  <span className="tag">{item.toUpperCase()}</span>
                ))}
              </div>
              <h3>CERTIFICATES</h3>
              <div className="skill__skills">
                {CERTIFICATES.map(({ name, link }) => (
                  <a target="_blank" href={link}>
                    <span className="tag">{name.toUpperCase()}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DownArrow color="tomato" />
    </section>
  )
}
