import React, { useEffect, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileWord } from "@fortawesome/free-regular-svg-icons"
import { faLinkedin, faFacebook } from "@fortawesome/free-brands-svg-icons"
import resumePdf from "../../images/BeckResume.pdf"
import "./styles.scss"

const Nav = ({ refProps }) => {
  const navRef = useRef()
  const ulRef = useRef()

  const handleScroll = () => {
    if (window.scrollY > 100 && navRef.current) {
      navRef.current.classList.add("sticky")
    } else {
      navRef.current.classList.remove("sticky")
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [])

  const clickAbout = ref => {
    if (refProps[ref]) {
      // ulRef.current.style.display = "none"
      refProps[ref].current.scrollIntoView()
    }
  }

  const handleInputClick = () => {
    // ulRef.current.style.display = "flex"
  }
  return (
    <nav ref={navRef} className="nav">
      <input
        onClick={handleInputClick}
        className="nav__input"
        type="checkbox"
        id="nav-btn"
      />
      <label className="nav__icon" for="nav-btn">
        <div class="nav__burger">
          <span class="line line1"></span>
          <span class="line line2"></span>
          <span class="line line3"></span>
        </div>
      </label>
      <ul className="nav__ul">
        <li>
          <button onClick={clickAbout.bind(null, "skillRef")}>SKILL</button>
        </li>
        <li>
          <button onClick={clickAbout.bind(null, "workRef")}>WORK</button>
        </li>
        <li>
          <button onClick={clickAbout.bind(null, "aboutRef")}>ABOUT</button>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/beck-yenwen/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </a>
        </li>
        <li>
          <a
            href={resumePdf}
            download
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Resume"
          >
            <FontAwesomeIcon icon={faFileWord} size="lg" />
          </a>
        </li>
      </ul>
      <ul ref={ulRef} className="nav__ul--mobile">
        <li>
          <button onClick={clickAbout.bind(null, "skillRef")}>SKILL</button>
        </li>
        <li>
          <button onClick={clickAbout.bind(null, "workRef")}>WORK</button>
        </li>
        <li>
          <button onClick={clickAbout.bind(null, "aboutRef")}>ABOUT</button>
        </li>
        {/* <li>
          <a target="_blank" href="https://becklinblog.azurewebsites.net/">
            POSTS
          </a>
        </li> */}
        <li>
          <a
            href="https://www.linkedin.com/in/beck-lin-4827b71b/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </a>
        </li>
        <li>
          <a
            href={resumePdf}
            download
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Resume"
          >
            <FontAwesomeIcon icon={faFileWord} size="lg" />
          </a>
        </li>
      </ul>
      <div className="nav__logo">
        <h1 className="nav__name" data-title="Beck">
          BECK
        </h1>
        <div className="nav__background"></div>
      </div>
    </nav>
  )
}

export default Nav
