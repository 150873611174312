import React from "react"
import { Link } from "gatsby"
// import { withTranslation } from "react-i18next"
import DownArrow from "../DownArrow"
import "./style.scss"

// function LandingScreen({ t, i18n }) {
function LandingScreen({ t }) {
  console.log(t)
  return (
    <section id="header">
      <div
        className="header__content fixed"
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-easing="easeOutCubic"
      >
        <h1 className="header__title">
          Hi, I'm&nbsp;&nbsp;
          <span className="header__name" data-title="Beck">
            BECK.
          </span>
        </h1>
        <span className="header__intro">
          FULLSTACK DEVELOPER with FOCUS ON GIS.
        </span>
        <span className="header__contact">
          <a href="mailto:beckyenwen@gmail.com">CONTACT ME</a>
        </span>
      </div>
      <DownArrow color="tomato" />
    </section>
  )
}

// export default withTranslation("testee")(LandingScreen)
export default LandingScreen
