import React, { useRef } from "react"
import Layout from "../components/Layout"
import LandingScreen from "../components/LandingScreen"
import Skill from "../components/Skill"
import Timeline from "../components/Timeline"
import About from "../components/About"
import Seo from "../components/Seo"
import Nav from "../components/Nav"
import { ModalProvider } from "../ModalContext"
// import "./i18n"

const IndexPage = () => {
  const skillRef = useRef()
  const workRef = useRef()
  const aboutRef = useRef()
  return (
    <Layout
      nav={
        <Nav
          refProps={{
            skillRef,
            workRef,
            aboutRef,
          }}
        />
      }
    >
      <ModalProvider>
        <LandingScreen />
        <Skill refProp={skillRef} />
        <Timeline refProp={workRef} />
        <About refProp={aboutRef} />
      </ModalProvider>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Home Page"
    description="A passionate javascript developer with 6+ years experiences focusing on building user-friendly web applications. Currently dedicate myself in grasping the holistic architect of web development with a goal to build resilient and performant web products."
    author="Beck"
  />
)

export default IndexPage
